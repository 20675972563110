.flex {
    display: flex;
}

.flex-row {
    flex-direction: row;
}

.flex-col {
    flex-direction: column;
}

.justify-center {
    justify-content: center;
}

.items-center {
    align-items: center;
}

.items-left {
    align-items: start;
}

.w-100 {
    width: 100%;
}

.w-50 {
    width: 50%;
}

.w-70 {
    width: 70%;
}

.w-40 {
    width: 40%;
}

.max-w-50 {
    max-width: 50%;
}

.max-w-70 {
    max-width: 60%;
}

.max-w-30 {
    max-width: 40%;
}

.mt-100 {
    margin-top: 100px;
}

.pt-100 {
    padding-top: 100px;
}

.text-gray-1 {
    color: #484848 !important;
}

.text-white {
    color: #FFFFFF;
}

.relative {
    position: relative;
}

.bg-white {
    background-color: white;
}

.text-left {
    text-align: start;
}

.pointer {
    cursor: pointer;
}

.pin-spacer {
    height: 100% !important;
    padding: unset !important;
}