.heading-1 {

    font-family: 'Open Sans' !important;
    font-size: 50px !important;
    font-weight: 700 !important;
    line-height: 60px !important;
    text-align: center !important;
}

.heading-2 {
    font-family: 'Open Sans' !important;
    font-size: 36px !important;
    font-weight: 700 !important;
    line-height: 43px !important;
    text-align: left !important;
}

.heading-3 {
    font-family: 'Open Sans' !important;
    font-size: 29px !important;
    font-weight: 700 !important;
    line-height: 40.6px !important;
    text-align: center !important;

}

.heading-4 {
    font-family: 'Open Sans' !important;
    font-size: 24px !important;
    font-weight: 700 !important;
    line-height: 33.6px !important;
    text-align: center !important;
}

.heading-5 {
    font-family: 'Open Sans' !important;
    font-size: 20px !important;
    font-weight: 400 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 28px !important;
    letter-spacing: normal !important;
}

.heading-6 {
    font-family: 'Open Sans' !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 25.2px !important;
    text-align: center;
}

.headline-b {
    font-family: Inter;
    font-size: 64px;
    font-weight: 700;
    line-height: 68.5px;
    text-align: left;
}


.body-1 {
    font-family: 'Open Sans' !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 19.6px !important;
    text-align: left !important;

}

.body-2 {
    font-family: 'Open Sans' !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 27.2px !important;
    text-align: left !important;


}