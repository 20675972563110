:root {
    --primary-10: #FF8001;
    --primary-20: #FF8001;
    --primary-30: #FF8001;
    --primary-40: #FF8001;
    --primary-50: #FF8001;
    --primary-60: #FF8001;
    --primary-70: #FF8001;
    --primary-80: #FF8001;
    --primary-90: #FF8001;
    --primary-95: #FF8001;

}