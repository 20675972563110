@use '@angular/material' as mat;
@include mat.core();

$coinigo-landing-theme: mat.define-theme((color: (theme-type: light,
                primary: mat.$orange-palette,
                tertiary: mat.$blue-palette,
            ),
            density: (scale: 0,
            )));

:root {
    @include mat.core-theme($coinigo-landing-theme);
    @include mat.core-color($coinigo-landing-theme);

    @include mat.button-theme($coinigo-landing-theme);
    @include mat.button-color($coinigo-landing-theme);

    @include mat.sidenav-theme($coinigo-landing-theme);
    @include mat.sidenav-color($coinigo-landing-theme);

    @include mat.expansion-theme($coinigo-landing-theme);
    @include mat.expansion-color($coinigo-landing-theme);

}

mat-sidenav {
    border-radius: 5px !important;
}

mat-expansion-panel {
    box-shadow: none !important;
}