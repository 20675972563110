@import './app/shared/styles/material-theme.scss';
@import './app/shared/styles/variables.scss';
@import './app/shared/styles/headings.scss';
@import './app/shared/styles/general.scss';
@import './app/shared/styles/swiper.scss';
@import './app/shared/styles/font.scss';


body {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  font-family: 'Open Sans', Inter;
  cursor: default;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: white;

  .container {
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}